<template>
  <CRow>
    <CCol sm="12">
      <CCard v-if="restaurantName">
        <CCardHeader>
          <CIcon name="cil-lock-unlocked" />
          <h5 class="d-inline ml-2">
            <h3 class="d-inline"><CBadge color="secondary">{{ this.restaurantName | uppercase }}</CBadge></h3>
            Permissions
          </h5>
          <div class="card-header-actions">
            <CButton v-if="restaurantId" color="link" :to="{name: 'View Restaurant', params: {'id': restaurantId}}" class="ml-2">
              <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back To Restaurant
            </CButton>
          </div>
        </CCardHeader>

        <CCardBody>
          <RestaurantPermissions :permissions.sync="permissions" :hasDeliveryService="hasDeliveryService" />
        </CCardBody>

        <CCardFooter class="sticky">
          <CButton
            color="primary"
            :disabled="submitted"
            @click="submitPermissions"
          >
            Submit
          </CButton>
        </CCardFooter>
      </CCard>

      <div v-else>
        <CSpinner v-if="loading" color="primary" />
        <h6 v-else>Restaurant not found!</h6>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import RestaurantPermissions from "@/components/Permissions/Restaurant.vue";

export default {
  name: "RestaurantPermissionList",
  components: {
    RestaurantPermissions,
  },

  data() {
    return {
      restaurantName: null,
      permissions: [],
      hasDeliveryService: false,

      loading: true,
      submitted: false,
    };
  },

  computed: {
    restaurantId() { return this.$route.params.restaurant_id; },
  },

  async mounted() {
    await this.getRestaurantName();
  },

  methods: {
    async getRestaurantName() {
      await this.$axios
        .get(this.$backend.RESTAURANT.GET_NAME.replace("{id}", this.restaurantId), {
          params: {
            fields: 'id',
            relationships: 'permissions,deliveryService',
          }
        })
        .then((response) => {
          const { restaurant_name, permissions, delivery_service } = response.data;

          this.restaurantName = restaurant_name;
          this.hasDeliveryService = !!(delivery_service);
          this.permissions = permissions
            .filter((el) => el.guard_name === "restaurant-api")
            .map(({ id, name, guard_name, description }) => ({ id, name, guard_name, description }));
        })
        .finally(() => (this.loading = false));
    },

    async submitPermissions() {
      this.submitted = true;

      try {
        const response = await this.$axios.post(this.$backend.RESTAURANT.PERMISSIONS.replace("{id}", this.restaurantId), {
          permissions: this.permissions.pluck('id')
        });

        this.permissions = response.data?.permissions
          .filter((el) => el.guard_name === "restaurant-api")
          .map(({ id, name, guard_name, description }) => ({ id, name, guard_name, description }));

        this.$toast.success(`Restaurant permissions updated.`);
      } catch (error) {
        this.$toast.error(`${error.response.data.message}`);
      } finally {
        this.submitted = false;
      }
    },
  },
};
</script>
