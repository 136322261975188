<template>
  <div>
    <CSpinner v-if="loading" size="sm" color="primary" />

    <ul class="list-group" v-for="p in permissionList" :key="p.id">
      <li class="list-group-item rounded-0">
        <div class="custom-control custom-checkbox">
          <input
            class="custom-control-input"
            :id="`permission_${p.id}`"
            type="checkbox"
            :value="p"
            v-model="restPermissions"
            :disabled="hasDeliveryService && p.name === 'manage-delivery-areas'"
          >
          <label class="cursor-pointer d-block custom-control-label" :for="`permission_${p.id}`">
            <p class="mb-0">{{ p.name | normalizeSentence }}</p>
            <span class="small font-italic text-muted">{{ p.description }}</span>
          </label>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: "RestaurantPermissions",
  components: {
  },
  props: {
    permissions: { default: [], type: Array },
    hasDeliveryService: { type: Boolean, default: false },
  },
  data() {
    return {
      permissionList: [],
      loading: true,
    }
  },
  computed: {
    restPermissions: {
      get() {
        return this.permissions;
      },
      set(newValue) {
        this.$emit("update:permissions", newValue);
      },
    },
  },

  async mounted() {
    await this.getPermissions();
  },

  methods: {
    async getPermissions() {
      const response = await this.$axios.get(this.$backend.PERMISSIONS.LIST);
      this.permissionList = response.data;
      this.loading = false;
    },
  },
};
</script>


